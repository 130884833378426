.youtube-wrap {
  position: relative;
  margin:30px 0;
  width: 360px;
  height: 150px;
}
.youtube-wrap .slider .item {
  margin: 0 30px;
  padding: 25px 38px 38px;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.dots_custom {
  padding-top:40px;
}
