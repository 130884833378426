.mockMobile {
  background: #f6f6f6;
  border-radius: 48px;
  position: absolute;
  width: 360px;
  aspect-ratio: 1/2;
  display: flex;
  left: 50%;
  border: solid 12px #f2f2f2;
  box-sizing: content-box;
  top: 50%;
  box-shadow: 0px 20px 30px #44444488;
}
.bezel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: solid 4px #333;
  border-radius: 40px;
}
.notibar {
  width: 100%;
  background: #f8f8f8;
  height: 32px;
  color: #33333366;

  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  padding: 4px 20px;
  letter-spacing: 0px;
  .battery {
    display: inline-flex;
    align-items: center;
    .body {
      width: 24px;
      height: 12px;
      border-radius: 3px;
      background: #33333318;
    }
    .tip {
      width: 2px;
      height: 6px;
      background: #33333318;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .wifi {
    padding: calc(8px / 3);
    transform: translateY(6px) scale(0.6);
  }

  .wifi,
  .wifi:before {
    display: inline-block;
    border: 8px double transparent;
    border-top-color: #33333333;
    border-radius: 50%;
  }

  .wifi:before {
    content: '';
    width: 0;
    height: 0;
  }
}
.innerPage {
  display: flex;
}

@keyframes clickMe {
  0% {
    transform: scale(1);
    filter: opacity(0.3);
  }
  50% {
    transform: scale(1.1);
    filter: opacity(1);
  }
  100% {
    transform: scale(1);
    filter: opacity(0.3);
  }
}

.clickMe {
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  animation: clickMe 1s cubic-bezier(0.2, 0.8, 0.2, 1) infinite;
}

.clickMeBlink {
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.clickMe::after {
  content: ' ';
  position: absolute;
  text-align: right;
  margin-top: -12px;
  width: 32px;
  height: 32px;
  display: block;
  right: -8px;
  background-size: contain;
  margin-left: 18px;
  background-repeat: no-repeat;

  background-image: url('../../../public/assets/pc/images/icon/dummy_cursor.png');
}

.screen {
  height: 100%;
  /* width: calc(100% - 12px); */
}

.powerButton {
  width: 4px;
  position: absolute;
  height: 120px;
  background: white;
  box-shadow:
    inset 2px 0px 6px 0px #44444433,
    0px 30px 30px #444444;
  transform: translateX(372px) translateY(160px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.volume {
  width: 4px;
  position: absolute;
  height: 80px;
  background: white;
  box-shadow:
    inset -2px 0px 8px 0px #44444433,
    0px 30px 30px #444444;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  &.up {
    transform: translateX(-16px) translateY(140px);
  }
  &.down {
    transform: translateX(-16px) translateY(240px);
  }
}

@keyframes modalreveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.메인홈 {
  .scrim {
    background: #171513cc;
    width: calc(100% - 12px);
    height: 100%;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-32px);
    z-index: 1;
    .modal {
      width: 85%;
      height: 50%;
      margin-bottom: 120px;
      background: white;
      display: flex;
      flex-direction: column;
      .title {
        width: 100%;
        background: #fe6b37;
        color: white;
        text-align: center;
        padding: 8px 0px;
        font-size: 20px;
      }
      .modalcontent {
        padding: 8px 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
        gap: 8px;
        .modalheader {
          border-bottom: solid 1px #000;
          font-weight: bold;
          font-size: 18px;
          padding-bottom: 8px;
          width: 100%;
        }
      }
      .modalfooter {
        height: 24px;
        background: #ddd;
        width: 100%;
      }
    }
    &.show {
      opacity: 1;
      /* animation: modalreveal 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
      animation-delay: 0.8s; */
    }
    &.hide {
      opacity: 0;
    }
  }
  &.visible {
    transform: translateX(0px);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  &.hide {
    transform: translateX(-360px);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }

  .nofiticationLabelNip {
    background: #fe6b37;
    width: 24px;
    height: 23px;
    display: block;
    position: absolute;
    right: 20px;
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    transform-origin: bottom center;
    clip-path: polygon(0% 100%, 50% 50%, 100% 100%);
    transform: translateY(-100%) scaleY(0);
    &.hide {
      opacity: 0;
      transform: translateY(-100%) scaleY(0);
    }
    &.show {
      opacity: 1;
      transition-delay: 0.6s;
      transform: translateY(calc(-100% + -49px)) scaleY(1);
    }
  }
  .nofiticationLabel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    background: #fe6b37;
    padding-left: 20px;
    font-weight: bold;
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    height: 0px;
    opacity: 0;
    &.show {
      height: 50px;
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
  .header {
    padding: 0px 20px;

    background: white;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: #fe6b37;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .content {
    padding: 30px 20px;
    display: flex;
    width: calc(100% - 12px);
    flex-direction: column;
    align-items: center;
    flex: 1;
    .storeRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .storeName {
        color: #000;
        font-family: NanumBarunGothic;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

.PLP {
  &.visible {
    transform: translateX(-364px);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  &.hide {
    transform: translateX(0px);
  }
  .header {
    padding: 0px 20px;
    /* padding-top: 40px; */
    height: 46px;
    background: #fe6b37;
    width: 360px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      color: white;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .searchArea {
      padding: 15px 20px;
    }
    .productList {
      flex: 1;
      width: 100%;
      .summary {
        background: white;
        display: flex;
        width: 100%;
        padding: 12px 258px 12px 20px;
        align-items: center;
        margin-bottom: 2px;
      }
      .restList {
        background: white;
        padding: 15px 20px;
        display: flex;
        margin-bottom: 2px;
        gap: 5px;
        width: 100%;
        .inner {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 15px;
          flex: 1;
          .upperLayer {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            width: 100%;
            .desc {
              display: flex;
              flex-direction: column;
              gap: 8px;
              flex: 1;
              .name {
                color: #1c1c1c;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }
              .descEtc {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;
                .misc {
                  display: flex;
                  flex-direction: column;
                  color: #5b5b5b;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .price {
                  color: #111;
                  text-align: right;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
.lowerLayer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .btn {
    width: 38px;
    border-radius: 2px;
    background: #e1e1e1;
    color: #1c1c1c;
    height: 28px;
  }

  .manipulate {
    display: inline-flex;
    .amount {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #777;
      height: 28px;
      width: 44px;
      background: #fff;
    }
  }
}
