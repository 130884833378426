.DemoSection {
  height: 540px;
  position: sticky;
  top: 0;
  margin: 0 auto;

  display: inline-flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f5f4f8;
  overflow: hidden;
  .title {
    margin: 32px auto;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1100px;
    aspect-ratio: auto;

    .headline {
      color: #424242;
      font-size: 40px;
      font-style: normal;
      font-weight: 700 !important;
      margin-bottom: 12px;
      text-align: center;
      letter-spacing: -3%;
      line-height: 48px;
    }
    .subHeader {
      font-size: 18px;
      font-weight: 500;
      color: #424242;
      padding-left: 4px;
    }
    .selector {
      box-sizing: content-box;
      border: solid 2px #ddd;
      position: absolute;
      background: white;
      z-index: -1;
      border-radius: 32px;
      transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    }
    .contents {
      display: flex;
      align-items: center;
    }
    .plays {
      width: fit-content;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
      font-size: 18px;
      border-radius: 40px;
      padding: 12px 16px;
      background: #e6e6e6;
      z-index: 2;
      .selected {
        font-weight: bold;
      }
    }
  }
  .contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 200px;
    max-width: 1200px;
    aspect-ratio: 2/1;
    margin: 0px auto;
    height: fit-content;

    .singlePlayRow {
      display: flex;
      gap: 32px;
    }
  }
  .playText {
    display: inline-flex;
    flex-direction: column;
  }
  .playTitle {
    font-weight: bold;
    font-size: 18px;
  }
  .playDescription {
    font-size: 14px;
    color: #666;
  }
}
.scrollWrapper {
  width: 1180px;
  height: 540px;
  background: #f5f4f8;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.stageIndicator {
  --off: 100px;
  height: calc(100% - (var(--off) * 4));
  top: calc(var(--off) * 2);
  width: 8px;
  position: absolute;
  border-radius: 15px;
  right: 16px;
  z-index: 1;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  display: flex;
  gap: 6px;
  flex-direction: column;
  overflow: hidden;

  filter: drop-shadow(0px 0px 4px #00000044);
  .chipChunk {
    background: #ffffff44;
    border-radius: 15px;
    width: 100%;
    flex: 1;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    &.open {
      flex: 10;
    }
    &.shrink {
      flex: 1;
    }
  }
}

.startNow {
  position: absolute;
  bottom: 32px;
  background: #242424;
  font-size: 24px;
  padding: 8px 24px;
  border-radius: 50px;
  color: white;
}

@keyframes ctaPop {
  0% {
    opacity: 0;
    width: 56px;
    height: 56px;
    transform: translateY(200%);
    .cta__content {
      opacity: 0;
    }
  }
  50% {
    opacity: 1;
    height: 56px;
    width: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 0;
    }
  }

  100% {
    opacity: 1;
    width: 300px;
    height: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 1;
    }
  }
}

@keyframes ctaPopR {
  0% {
    opacity: 1;
    width: 300px;
    height: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 1;
    }
  }

  50% {
    opacity: 1;
    height: 56px;
    width: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 0;
    }
  }

  100% {
    opacity: 0;
    width: 56px;
    height: 56px;
    transform: translateY(200%);
    .cta__content {
      opacity: 0;
    }
  }
}

.cta {
  position: sticky;
  z-index: 10;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  transform: translateY(200%);
  opacity: 0%;
  padding: 0px 28px;
  max-width: 395px;
  border-radius: 50px;
  display: grid;
  place-items: center;
  height: 56px;
  backdrop-filter: blur(16px);
  background: #333;
  font-size: 18px;
  border: solid 2px #555;
  box-shadow: 0 0 8px #00000022;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  will-change: opacity, width, height, transform;
  &.ongoing {
    opacity: 1;
    width: 300px;
    height: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 1;
    }
    &.play {
      animation: ctaPop 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) both;
      animation-delay: 0.5s;
    }

    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  &.after {
    opacity: 1;
    width: 300px;
    height: 56px;
    transform: translateY(50%);
    .cta__content {
      opacity: 1;
    }
  }
  &.before {
    opacity: 0;
    width: 56px;
    height: 56px;
    transform: translateY(200%);
    .cta__content {
      opacity: 0;
    }
    animation: ctaPopR 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) both;
    animation-delay: 0.5s;
  }

  .cta__content {
    white-space: nowrap;
    color: white;
  }
  .icon {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fe6b37;
    border: solid 6px #333;
    right: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
  }
  &.실시간취합 > .icon {
    background: linear-gradient(90deg, hsl(358, 90%, 71%) 0%, hsl(16, 90%, 61%) 100%);
  }
  &.공지사항 > .icon {
    background: linear-gradient(135deg, rgba(164, 136, 221, 1) 0%, rgba(119, 207, 255, 1) 130%);
  }
  &.브랜드관리 > .icon {
    background: linear-gradient(135deg, #ffb563, rgba(255, 144, 168, 1));
  }
}
