.subHeader {
  color: white;
  text-align: center;
  font-family: Noto Sans CJK KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.6px;
  display: inline-flex;
  flex-direction: column;
  bottom: 32px;
  z-index: 8;
  width: 360px;
}
.input {
  height: 32px;
  outline: none;
  height: 52px;
  border-radius: 5px;
  width: 100%;
  flex: 1;
  font-size: 18px;
  color: #333;
  border: none;
  background: transparent;
  caret-color: #fe6b37;
  flex: 1;
  &::placeholder {
    color: #ccc;
  }
}

.simple-signup {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 56px;
  padding-left: 12px;
  border: solid 2px #fe6b37;
  background: #ffffffee;
  backdrop-filter: blur(8px);
  border-radius: 64px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .submit {
    background: #fe6b37;
    color: white;
    display: inline-flex;
    justify-content: center;

    font-size: 18px;
    font-weight: bold;
    align-items: center;
    width: max-content;
    padding: 0px 12px;
    padding-right: 24px;
  }
}

.cta-wrap {
  color: #333;
  text-align: center;
  font-family: Noto Sans CJK KR;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.54px;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  span {
    padding-left: 12px;
  }
}
