.DemoStage {
  --stage-background: #edeaf3;
  --stage-foreground: #533b8522;
  margin: 0px auto;
  max-width: 1180px;
  width: 100%;
  height: 540px;
  border-radius: 10px;
  overflow: hidden;
  background: #f5f4f8;
  margin-right: auto;
  position: relative;
  box-shadow: 0 4px 12px #00000012;
  opacity: 1;
  transition: background 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.StageWrapper {
  margin: 0px auto;
  width: 100%;
  max-width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform-origin: top center;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.offStage {
    transform: scale(1.5) translateY(200px);
    opacity: 0.1;
    .DemoStage .title {
      transform: scale(1.05) translateY(100px);
      opacity: 0;
    }
  }
  &.onStage {
    transform: scale(1) translateY(0px);
    opacity: 1;
    .title {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 40px;
  gap: 18px;
}

.resetButton {
  border-radius: 24px;
  padding: 8px 20px;
  padding-right: 16px;
  position: absolute;
  background: white;
  color: #333;
  bottom: 16px;
  margin: 0px auto;
  left: 50%;
  font-size: 18px;
  box-shadow: 0 4px 8px #00000022;
  border: solid 1px #e6e6e6;
  transform: translateX(-50%) translateY(200%);
  display: flex;
  align-items: center;
  gap: 4px;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  .reseticon {
    fill: #666;
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    height: 24px;
  }

  &.visible {
    transform: translateX(-50%);
    transition-delay: 1s, 0s;
    transition-property: transform, background-color;
  }
}

.resetButton:hover {
  background: #f2f2f2;
  .reseticon {
    transform: rotateZ(-45deg);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
}

.resetButton:active {
  background: #e8e8e8;
  .reseticon {
    transform: rotateZ(-60deg);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
}

.mainActorChip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 16px;
  backdrop-filter: blur(8px);
  border-radius: 32px;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  box-shadow: 0 4px 8px #00000022;
  padding: 1px;
  .content {
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    background: #ffffffdd;
    color: #1f1f1f;
    position: relative;
    border-radius: 32px;
    font-size: 18px;
  }
  &.show {
    top: 16px;
  }
  &.hide {
    top: -32px;
  }
}

.startNow {
  display: inline-flex;
  width: fit-content;
  justify-content: center;
  font-size: 24px;
  padding: 12px 18px;
  border: solid 4px #fe6b37;
  border-radius: 80px;
  cursor: pointer;
  background: white;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
  transform: translateY(100%);
  &.hide {
    opacity: 0;
    transform: translateY(100%);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
    pointer-events: none;
  }
  &.show {
    opacity: 1;
    transform: translateY(0%);
    transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  &:hover {
    background: #fe6b37;
    color: white;
  }
}
