.mockDesktop {
  background: #f6f6f6;
  border-radius: 16px;
  /* overflow: hidden; */
  position: absolute;
  width: 1440px;
  aspect-ratio: 1440/900;

  display: flex;
  box-sizing: content-box;
  border: solid 32px #333;
  left: 50%;
  top: 50%;
  &:hover {
    box-shadow: 0px 8px 8px #44444488;
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.blinkBookMark {
  animation: pop 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

@keyframes clickMe {
  0% {
    transform: scale(1);
    filter: opacity(0.3);
  }
  50% {
    transform: scale(1.02);
    filter: opacity(1);
  }
  100% {
    transform: scale(1);
    filter: opacity(0.3);
  }
}

.clickMe {
  animation: clickMe 1s infinite;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
}
.clickMe::after {
  content: ' ';
  position: absolute;
  text-align: right;
  margin-top: 40px;
  width: 32px;
  height: 32px;
  display: block;
  right: -8px;
  background-size: contain;
  margin-left: 18px;
  background-repeat: no-repeat;
  background-image: url('../../../public/assets/pc/images/icon/dummy_cursor.png');
}

.SideBar {
  width: 208px;
  display: flex;
  flex-direction: column;
  .header {
    height: 168px;
    background: #fe6b37;
    border: solid 1px #f95632;
    .logo {
      height: 60px;
      padding-top: 20px;
      padding-left: 24px;
      border-bottom: solid 1px #f95632;
    }
    .user {
      padding: 24px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 106px;
      gap: 8px;
      .유통사 {
        color: #fff;
        font-family: Noto Sans KR;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.6px;
      }
      .사업자정보 {
        color: #fff;
        font-family: Noto Sans KR;
      }
    }
  }
  .list {
    background: #eeeeee;
    flex: 1;
    height: 100%;
    .sideBarSubMenu {
      background: #a2a2a2;
      display: flex;
      flex-direction: column;
      padding: 16px 20px;
      gap: 16px;
      transform: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
      will-change: height;
      height: 0px;
      overflow: hidden;
      color: #333;
      &.show {
        height: initial;
        transform: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
      }
      &.hide {
        padding: 0px;
        transform: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
      }
      .selected {
        color: white;
      }
    }
    .item {
      background: #eeeeee;
      border-bottom: solid 1px #a2a2a2;
      height: 52px;
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      transform-origin: center left;
      .expanded {
        transform: rotateZ(180deg);
        transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
  .nested {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
  .titleRow {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    border-bottom: solid 1px #e6e6e6;
    .chunk {
      display: inline-flex;
      align-items: center;
      gap: 20px;
    }
  }
  .innerContent {
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
  }
}

.notice {
  display: flex;
  gap: 40px;
  align-items: center;
  margin-bottom: 40px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .bar {
    padding: 6px 12px;
    background: #eeeeee;
    width: 80%;
  }
}
.dashboardContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .title {
    font-size: 24px;
    color: #888888;
  }
  .boxRow {
    display: flex;
    gap: 20px;
    .box {
      font-size: 18px;
      background: #eeeeee;
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .num {
      font-weight: bold;
    }
    .출고전 {
      border: solid 1px #fe6b37;
      color: #fe6b37;
    }
    .출고완료 {
      color: #888888;
    }
  }
  .checkList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 150px;
    .checkItem {
      flex: 1;
      display: flex;
      width: 350px;
      .field {
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 50%;
      }
      .value {
        box-sizing: border-box;
        border: solid 1px #a2a2a2;
      }
      .name {
        color: white;
        background: #fe6b37;
      }
    }
  }
  .주문현황 {
    display: flex;
    flex-direction: column;
    width: 490px;
    gap: 20px;
    .item {
      border: solid 1px #a2a2a2;
      background: white;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      height: 180px;
      .sectionName {
        background: #eeeeee;
        padding: 8px;
        display: flex;
        justify-content: center;
      }
      .sectionContent {
        padding: 24px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: bold;
      }
      .sectionContent2 {
        color: #a2a2a2;
        padding: 24px;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  .공지탭_list {
    display: flex;
    flex-direction: column;
    width: 450px;
    border: solid 1px #a2a2a2;
    height: 380px;
    .공지탭_row {
      display: flex;
      .공지탭 {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        &.off {
          background: #eeeeee;
          border-left: solid 1px #a2a2a2;
          border-bottom: solid 1px #a2a2a2;
        }
      }
    }
    .공지_list {
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      gap: 16px;
      .item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.checkbox {
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #aaa;
  width: 16px;
  height: 16px;
}

@keyframes emphasis {
  0% {
    background: #fff;
  }
  50% {
    background: #fe6b3766;
  }
  100% {
    background: #fff;
  }
}
.orderList {
  .table {
    .headerRow {
      display: grid;
      grid-template-columns: 30px 50px 110px 110px 100px 50px 50px 80px 100px 50px 50px;

      gap: 20px;
      width: 1200px;
      overflow: hidden;
      background: #eee;
      border-bottom: solid 2px #e6e6e6;
      padding: 12px;
      .col {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
    .contentRow {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .innerRow {
        transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
        &.showNewRow {
          transform: translateY(0px);
        }
        &.hideNewRow {
          transform: translateY(-62px);
        }
      }
    }
    .item {
      display: grid;
      grid-template-columns: 30px 50px 110px 110px 100px 50px 50px 80px 100px 50px 50px;
      gap: 20px;
      padding: 12px;
      border-bottom: solid 1px #e6e6e6;
      .col {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: pre;
      }
      &.emphasis {
        animation: emphasis 0.75s infinite;
      }
    }
  }
}

.typewriter {
  position: absolute;
  overflow: hidden;
  border-right: 0.15em solid black;
  white-space: nowrap;
  /* margin: 0 auto; */
  letter-spacing: 0.15em;
  margin-top: -240px;
  margin-left: 245px;
  max-width: fit-content;
  font-size: 16px;
  font-weight: bold;
  opacity: 0;

  animation:
    show 0s linear forwards,
    typing 10s steps(120, end),
    blink-caret 0.8s step-end infinite;
  animation-delay: 1s;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: #00000022;
    transform: all 0.2s linear;
  }
  50% {
    border-color: #fe6b37;
    transform: all 0.2s linear;
  }
}

.brandProductList {
  display: flex;
  margin-left: 41px;
  margin-top: -4px;

  .brandProductListHeader {
    width: 175.5px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-weight: bold;
    background: #f4f4f4;
  }
  .totalItems {
    position: absolute;
    left: 439px;
    top: 400px;
    background: white;
    padding-right: 80px;
    padding-left: 8px;
    font-weight: bold;
  }
  .brandName {
    position: absolute;
    left: 439px;
    top: 230px;
    background: white;
  }
  .tableArea {
    .tableContent {
      padding: 0px 17px;
      margin-top: -4px;
    }
    .headerRow {
      display: grid;
      height: fit-content;
      grid-template-columns: 30px 30px 110px 160px 100px 50px 100px 80px 100px 50px 50px;
      background: #f4f4f4;
      font-weight: bold;

      .col {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        height: fit-content;
        word-break: keep-all;
        align-self: center;
      }
    }
    .tableRow {
      display: grid;
      grid-template-columns: 30px 30px 110px 160px 100px 50px 100px 80px 100px 50px 50px;
      padding: 8px 0px;
      border-bottom: solid 1px #e6e6e6;
      .col {
        text-align: center;
        padding: 10px 10px;
      }
    }
  }
}
@keyframes rowMove {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(66px);
  }
}

@keyframes modalreveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.addBrandModal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000aa;
  z-index: 3;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  &.show {
    animation: modalreveal 0.5s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
  }
  .modal {
    display: inline-flex;
    width: 500px;
    flex-direction: column;

    .title {
      width: 100%;
      background: #fe6b37;
      padding: 8px 0px;
      padding-left: 8px;
      font-weight: bold;
      color: white;
      font-size: 18px;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      background: white;
      padding: 40px;
      .contentrow {
        display: flex;
        gap: 30px;
        justify-content: center;
        .dummyInput {
          margin-left: 28px;
          border: solid 1px #ccc;
          height: 24px;
          flex: 1;
        }
        .dummyButton {
          border: solid 1px #ccc;
          padding: 8px 32px;
          &.primary {
            border: none;
            background: #fe6b37;
            color: white;
          }
        }
      }
    }
  }
}

.accountListPage {
  display: flex;
  flex-direction: column;
  .overlap {
    position: absolute;
    display: inline-flex;
    background: white;
    margin-top: -44px;
    margin-left: 40px;
    padding-right: 80px;
    z-index: 2;
    gap: 20px;
    align-items: center;
    padding-left: 8px;
    font-weight: bold;
  }
  .accountListTable {
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    width: 1161px;
    overflow: hidden;

    .headerRow {
      display: grid;
      background: #f4f4f4;
      z-index: 1;
      grid-template-columns: 60px 30px 110px 160px 100px 100px 100px 100px 100px 80px 100px 100px;
      .col {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        height: fit-content;
        word-break: keep-all;
        align-self: center;
      }
    }
    .rows {
      position: absolute;
    }

    .tableRow {
      display: grid;
      grid-template-columns: 60px 30px 110px 160px 100px 100px 100px 100px 100px 80px 100px 100px;
      border-bottom: solid 1px #e6e6e6;
      animation: rowMove 0.5s linear;
      background: white;
      height: 66px;
      .col {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        height: fit-content;
        word-break: keep-all;
        align-self: center;
      }
    }
  }
}

.brandListPage {
  display: flex;
  flex-direction: column;
  .brandListTableContent {
    .row {
      margin-left: 40px;
      display: grid;
      align-items: center;
      justify-items: center;
      height: 48px;
      width: 1120px;
      border-bottom: solid 1px #ccc;
      grid-template-columns: 90px 455px 103px 103px 161.2px 110px 110px;
      .clickMe {
        animation:
          clickMe 1s infinite,
          emphasis 1s infinite;
        transition: all 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
      }
    }
  }
}

.brandAccountListPage {
  .nameCover {
    background: white;
    opacity: 1;
    position: absolute;
    top: 190px;
    left: 430px;
    width: 200px;
  }
  .tableRow {
    display: flex;
    padding-left: 39px;
    margin-top: -8px;
    .rowHead {
      width: 168px;
      background: #f4f4f4;
      padding-left: 20px;
      display: flex;
      font-weight: bold;
      align-items: center;
    }
    .content {
      margin-top: -4px;
    }
    .account {
      animation: emphasis 1s infinite;
      width: calc(100% - 30px);
      height: 40px;
      display: grid;
      grid-template-columns: 55px 360px 100px 90px 100px 110px 50px;
      border-bottom: solid 1px #ccc;
      place-items: center;
      margin-left: 14px;
    }
  }
}

.neck {
  background: linear-gradient(180deg, #121212 0%, #333 20%, #333 100%);
  border-left: solid 4px #444;
  border-right: solid 4px #444;
  width: 200px;
  position: absolute;
  left: 50%;
  height: 300px;
  top: 100%;
  z-index: -1;
  transform: translateX(-50%) translateY(32px);
}

.shiny {
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  opacity: 0.3;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(234, 250, 255, 1) 32%,
    rgba(234, 250, 255, 1) 38%,
    rgba(234, 250, 255, 0) 45%,
    rgba(234, 250, 255, 1) 57%,
    rgba(234, 250, 255, 0) 100%
  );
}
