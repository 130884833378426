.section {
  position: absolute;
  height: 100%;
  transition: transform 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateY(0px);
    z-index: 1;
  }
  &.off {
    transform: translateY(100%);
    z-index: 0;
  }
}
.ytsBg1 {
  position: absolute;
  transform: translateX(40px) translateY(160px);
}

.ghost {
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    opacity: 1;
  }
  &.off {
    opacity: 0;
  }
}

.yts1 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateY(80px);
  }
  &.off {
    transform: translateY(160px);
  }
}
.ytsbubble1 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.5s;
  transform: translateX(-255px) translateY(-30px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.yts2 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(200px) translateY(260px);
  }
  &.off {
    transform: translateX(200px) translateY(600px);
  }
}
.ytsbubble2 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.8s;
  transform: translateX(-200px) translateY(-30px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.yts3 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(720px) translateY(142px);
  }
  &.off {
    transform: translateX(720px) translateY(170px);
  }
}

.yts4 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(870px) translateY(90px);
  }
  &.off {
    transform: translateX(870px) translateY(500px);
  }
}

.ytsbubble3 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 1s;
  transform: translateX(-225px) translateY(-70px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.frchBg1 {
  position: absolute;
  transform: translateX(100px) translateY(210px);
  z-index: 0;
}

.frch1 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateY(100px);
  }
  &.off {
    transform: translateY(300px);
  }
}
.frchbubble1 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.5s;
  transform: translateX(-195px) translateY(-42px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.frch2 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(600px) translateY(125px);
  }
  &.off {
    transform: translateX(600px) translateY(1200px);
  }
}
.frchbubble2 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.8s;
  transform: translateX(-342px) translateY(40px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.frch3 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(900px) translateY(90px);
  }
  &.off {
    transform: translateX(900px) translateY(300px);
  }
}

.frchbubble3 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 1s;
  transform: translateX(-240px) translateY(-85px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}

.jdmi1 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateY(115px);
  }
  &.off {
    transform: translateY(300px);
  }
}
.jdmibubble1 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.5s;
  transform: translateX(-500px) translateY(-82px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}
.jdmi2 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(550px) translateY(165px);
  }
  &.off {
    transform: translateX(550px) translateY(700px);
  }
}
.jdmibubble2 {
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 0.8s;
  transform: translateX(-210px) translateY(-90px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}
.jdmi3 {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  &.on {
    transform: translateX(850px) translateY(70px);
  }
  &.off {
    transform: translateX(850px) translateY(300px);
  }
}

.receipt {
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 1s;
  &.receipt1 {
    z-index: 0;
    &.on {
      transform: rotate(0deg) translateX(-50px) translateY(60px);
    }
    &.off {
      transform: rotate(2.5deg) translateX(0px) translateY(60px);
    }
  }
  &.receipt2 {
    z-index: 1;
  }
  &.receipt3 {
    z-index: 2;
    &.on {
      transform: rotate(0deg) translateX(50px) translateY(10px);
    }
    &.off {
      transform: rotate(-4.73deg) translateX(0px) translateY(10px);
    }
  }
}
.jdmibubble3 {
  z-index: 4;
  position: absolute;
  transition: opacity 0.5s cubic-bezier(0.2, 0.8, 0.2, 1);
  transition-delay: 1s;
  transform: translateX(40px) translateY(-60px);
  &.on {
    opacity: 1;
  }
  &.off {
    transition: opacity 0s cubic-bezier(0.2, 0.8, 0.2, 1);
    opacity: 0;
  }
}
