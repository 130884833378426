.section4 {
  padding: 40px 30px 50px;
  background: #533b85;
}
.section4 h3 {
  margin-bottom: 4px;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 28px;
  letter-spacing: -2.15px;
  margin-bottom: 30px;
}
.section4 .desc {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.9px;
  text-align: center;
  color: #fff;
}
.section4 .white-box {
  padding: 20px 0 30px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
}
.section4 .white-box + .white-box {
  margin-top: 30px;
}
.section4 .white-box .num {
  display: block;
  margin-bottom: 3px;
  font-size: 30px;
  font-weight: bold;
  color: #69a6df;
  text-align: center;
  line-height: 32px;
  opacity: 0.5;
}
.section4 .white-box dt {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  line-height: 26px;
  letter-spacing: -2.1px;
}
.section4 .white-box dt img {
  display: block;
  width: 200px;
  margin: 19px auto 0;
}
.section4 .white-box dd {
  margin-bottom: 12px;
  font-size: 16px;
  color: #000;
  line-height: 22px;
  letter-spacing: -1.41px;
}
.section4 .white-box .btn-start {
  display: block;
  max-width: 224px;
  height: 40px;
  margin: 0 auto;
  padding-top: 9px;
  background: #402578;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
}
.section4 .white-box .row + .row {
  position: relative;
  margin-top: 30px;
  padding-top: 19px;
  border-top: 1px dashed #979797;
}
.section4 .white-box p {
  margin-bottom: 8px;
  font-size: 15px;
  color: #000;
  line-height: 20px;
  text-align: center;
  letter-spacing: -1.5px;
}
.section4 .white-box .btn-detail {
  display: block;
  max-width: 224px;
  height: 40px;
  margin: 0 auto;
  padding-top: 8px;
  background: #fff;
  border: 1px solid #666;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  line-height: 20px;
}
