.reviewList {
  container-name: reviewList;
  container-type: inline-size;
}

.review {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  font-size: 16px;
  gap: 10px;
  width: 100%;
  min-width: 442px;
}

@container reviewList (max-width: 442px) {
  .review {
    aspect-ratio: 442 / 130;
    transform: scaleX(calc(442px / 130px * 100%));
    min-width: 0px;
    strong,
    span {
      word-break: keep-all;
    }
  }
}
