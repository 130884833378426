@keyframes reviewYTS {
  0% {
    transform: translateY(-130px);
  }
  100% {
    transform: translateY(calc(-130px * 4 + -24px * 3 + -130px + -20px));
  }
}

@keyframes reviewFRCH {
  0% {
    transform: translateY(-130px);
  }
  100% {
    transform: translateY(calc(-130px * 4 + -24px * 3 + -130px + -20px));
  }
}

@keyframes reviewJDMI {
  0% {
    transform: translateY(-130px);
  }
  100% {
    transform: translateY(calc(-130px * 4 + -24px * 3 + -130px + -20px));
  }
}

.reviews {
  transition: all 10s linear;
  &.유통사 {
    animation: reviewYTS 12s linear infinite;
  }
  &.프랜차이즈 {
    animation: reviewFRCH 12s linear infinite;
  }
  &.중도매인 {
    animation: reviewJDMI 12s linear infinite;
  }
}
