.section9 {
  padding: 30px 30px 60px;
  background: #f0f2f6;
  text-align: center;
}
.section9 h3 {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  line-height: 28px;
  letter-spacing: -1.2px;
}
.section9 .desc {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  line-height: 28px;
  letter-spacing: -1.65px;
}
.section9 .insert-area {
  margin-bottom: 40px;
  padding: 20px 22px 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 4px 10px 0 rgba(38, 29, 72, 0.25);
}
.section9 .insert-area .label {
  display: block;
  margin-bottom: 6px;
  font-size: 15px;
  color: #000;
  text-align: left;
  line-height: 18px;
  letter-spacing: -1.12px;
}
.section9 .insert-area input {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  background: #f0f2f6;
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  color: #000;
}
.section9 .insert-area input::placeholder {
  font-size: 12px;
  color: #777;
}
.section9 .insert-area textarea {
  display: block;
  width: 100%;
  height: 80px;
  padding: 6px 10px;
  background: #f0f2f6;
  border: 0;
  border-radius: 5px;
  font-size: 13px;
  color: #000;
  resize: none;
}
.section9 .insert-area textarea::placeholder {
  font-size: 12px;
  color: #777;
}
.section9 .insert-area .row + .row {
  margin-top: 16px;
}
.section9 .insert-area .btn-wrap {
  margin-top: 20px;
}
.section9 .insert-area .btn-wrap .btn-send {
  display: block;
  width: 150px;
  height: 40px;
  margin: 0 auto;
  border: 1px solid #666;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: center;
  line-height: 20px;
  letter-spacing: -1.17px;
}
.section9 .contact {
  text-align: left;
}
.section9 .contact .tel {
  position: relative;
  padding: 0 0 0 20px;
}

.section9 .contact .tel strong {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  line-height: 32px;
  vertical-align: top;
  margin-right: 4px;
}
.section9 .contact .tel span {
  display: inline-block;
  font-size: 12px;
  color: #9d9d9d;
  line-height: 32px;
  vertical-align: top;
}
.section9 .contact .email {
  position: relative;
  margin-top: 16px;
  padding: 0 0 0 20px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 32px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.section9 .contact .location {
  position: relative;
  margin-top: 16px;
  padding: 2px 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 26px;
  display: flex;
  align-items: flex-start;
}
