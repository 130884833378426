.section3 {
  padding: 95px 0 0;
}
.section3 h3 {
  font-size: 40px;
  font-weight: bold;
  color: #000;
  text-align: center;
  line-height: 1;
}
.section3 .row {
  height: 480px;
  padding: 115px 0 0 60px;
}
.section3 .row + .row {
  border-top: 1px solid #ccc;
}
.franchise .section3 .row {
  border-top: none;
  border-bottom: 1px solid #ccc;
}
.section3 .row dl dt {
  margin-bottom: 25px;
  font-size: 32px;
  color: #000;
  line-height: 44px;
}
.section3 .row dl dd {
  font-size: 20px;
  color: #000;
  line-height: 30px;
}
.section3 .row.row-right {
  padding-left: 690px;
}
.section3 .row.type8 {
  padding-top: 148px;
}
.section3 .row.type9 {
  height: 540px;
}
.franchise .section3 .row.type9 {
  height: 480px;
}
