@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 96px * 7 + 12px * 7));
  }
}

.slide {
  animation: slide 100s linear infinite;
}
