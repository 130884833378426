.section8 {
  padding: 40px 0 58px;
  background: #2b1557;
  text-align: center;
}
.section8 h3 {
  margin-bottom: 35px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  line-height: 28px;
  letter-spacing: -2.15px;
}

.slick-dots li button:before {
  visibility: hidden;
}
.section8 .btn-wrap {
  margin-top: 80px;
}
.section8 .btn-usage-guide {
  display: block;
  max-width: 224px;
  height: 40px;
  margin: 0 auto;
  padding-top: 10px;
  background: #fff;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  text-align: center;
  line-height: 20px;
  letter-spacing: -1.17px;
}
