.section1 {
  position: relative;

  background-size: 100% auto;
  text-align: center;
  padding-top: 48px;
  padding-bottom: 40px;
}
.section1_sale {
  content: '';
  position: absolute;
  bottom: 5vw;
  right: 4vw;
  width: 85px;
  height: 85px;
}

.section1 h3 strong {
  font-weight: bold;
}
.section1 p {
  color: #333;
  text-align: center;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -2.476px;
  padding-bottom: 28px;
}

.section1 .btn-start {
  display: block;
  width: 220px;
  height: 55px;
  margin: 0 auto;
  padding-top: 13px;
  background: #ed6434;
  border-radius: 45px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 27px;
  letter-spacing: -0.03em;
}
