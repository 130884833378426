.youtube-wrap {
  margin-top:70px;
}
.dots_custom {
  padding-top:40px;
}
.comstom_paging li {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  padding: 0;
  cursor: pointer;
  width: 200px;
  height: 113px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}
.arrow-content-none::before{
  content: '' !important;
}

