.section1 {
  position: relative;
  padding: 45px 0 77%;
  background-size: 100% auto;
  text-align: center;
  /* padding-bottom: 32px; */
}
.section1_sale {
  content: '';
  position: absolute;
  bottom: 5vw;
  right: 4vw;
  width: 85px;
  height: 85px;
}
.section1 h3 {
  margin-bottom: 10px;
  font-size: 14px;
  color: #000;
  letter-spacing: -0.03em;
}
.section1 h3 strong {
  font-weight: bold;
}
.section1 p {
  position: relative;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #000;
  line-height: 34px;
  letter-spacing: -0.03em;
}
.franchise .section1 p {
  margin-bottom: -24px;
}
.section1 p:before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  width: 199px;
  height: 18px;
  background: rgba(254, 107, 56, 0.2);
  transform: translate(-50%, 0);
}
.franchise .section1 p:before {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 50%;
  width: 286px;
  height: 18px;
  background: rgba(254, 107, 56, 0.2);
  transform: translate(-50%, 0);
}
.section1 .btn-start {
  display: block;
  width: 220px;
  height: 55px;
  margin: 0 auto;
  padding-top: 13px;
  background: #ed6434;
  border-radius: 45px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 27px;
  letter-spacing: -0.03em;
}
